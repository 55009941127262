import "./NewWarehouse.css";
import data from "../data.json";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Dropdown, Menu, Modal, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { CustomTable } from "../components/CustomTable";
import { StatusIcons } from "../components/StatusIcons";
import { PopUpMessage } from "../components/PopUpMessage";
import { CustomCard } from "../components/CustomCard";

const gatewayColumns = [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
  },
  {
    key: "inWhere",
    dataIndex: "inWhere",
    title: "Device Location",
    filters: [
      {
        text: "client",
        value: "client",
      },
      {
        text: "warehouse",
        value: "warehouse",
      },
    ],
    render: (text, record) => (
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        {StatusIcons[record.inWhere]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.inWhere.includes(value),
    sorter: (a, b) => a.inWhere.localeCompare(b.inWhere),
  },
  {
    key: "ayarlar",
    dataIndex: "ayarlar",
    title: "Ayarlar",
    width: window.innerWidth / 17,
    render: (_, record) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={() => handleEdit(record)}>
              Edit
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleDelete(record)}>
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <p style={{ cursor: "pointer" }}>
          More <DownOutlined />
        </p>
      </Dropdown>
    ),
  },
];

const sensorColumns = [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
  },
  {
    key: "inWhere",
    dataIndex: "inWhere",
    title: "Device Location",
    filters: [
      {
        text: "client",
        value: "client",
      },
      {
        text: "warehouse",
        value: "warehouse",
      },
    ],
    render: (text, record) => (
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        {StatusIcons[record.inWhere]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.inWhere.includes(value),
    sorter: (a, b) => a.inWhere.localeCompare(b.inWhere),
  },
  {
    key: "ayarlar",
    dataIndex: "ayarlar",
    title: "Ayarlar",
    width: window.innerWidth / 17,
    render: (_, record) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={() => handleEdit(record)}>
              Edit
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleDelete(record)}>
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <p style={{ cursor: "pointer" }}>
          More <DownOutlined />
        </p>
      </Dropdown>
    ),
  },
];

const bundleColumns = [
  {
    key: "bundleID",
    dataIndex: "bundleID",
    title: "Bundle ID",
    render: (_, record) => {
      return record.id ? (
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          {record.id}
        </div>
      ) : (
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>-</div>
      );
    },
    sorter: (a, b) => {
      const valueA = a.id === null ? 0 : a.id;
      const valueB = b.id === null ? 0 : b.id;

      return valueA - valueB;
    },
  },
  {
    key: "inWhere",
    dataIndex: "inWhere",
    title: "Device Location",
    filters: [
      {
        text: "client",
        value: "client",
      },
      {
        text: "warehouse",
        value: "warehouse",
      },
    ],
    render: (text, record) => (
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        {StatusIcons[record.inWhere]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.inWhere.includes(value),
    sorter: (a, b) => a.inWhere.localeCompare(b.inWhere),
  },
  {
    key: "ayarlar",
    dataIndex: "ayarlar",
    title: "Ayarlar",
    width: window.innerWidth / 17,
    render: (_, record) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={() => handleEdit(record)}>
              Edit
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleDelete(record)}>
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <p style={{ cursor: "pointer" }}>
          More <DownOutlined />
        </p>
      </Dropdown>
    ),
  },
];

const handleEdit = (record) => {};
const handleDelete = (record) => {};

export const NewWarehouse = () => {
  const [gatewayData, setGatewayData] = useState([]);
  const [sensorData, setSensorData] = useState([]);
  const [bundleData, setBundleData] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);

  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedGateways, setSelectedGateways] = useState([]);
  const [selectedBundles, setSelectedBundles] = useState([]);

  useEffect(() => {
    setGatewayData(
      data.gatewayData.filter(
        (item) => item.inWhere === "warehouse" && item.isBundled === "no"
      )
    );
    setSensorData(
      data.sensorData.filter(
        (item) => item.inWhere === "warehouse" && item.isBundled === "no"
      )
    );
    setBundleData(
      data.bundleData.filter(
        (item) => item.id !== null && item.inWhere === "warehouse"
      )
    );
  }, []);

  const handleMakeBundleButtonClick = () => {
    if (selectedGateways.length === 1 && selectedSensors.length === 4) {
      setIsModalOpened(true);
    } else {
      PopUpMessage({
        type: "warning",
        content: "Pick 1 gateway and 4 sensors!",
      });
    }
  };

  const makeBundle = () => {
    if (selectedGateways.length === 1 && selectedSensors.length === 4) {
      const newBundleID = Math.floor(Math.random() * 1000);

      const newGateway = {
        ...selectedGateways[0],
        isBundled: "yes",
        bundleID: newBundleID,
      };

      const newSensors = selectedSensors.map((sensor) => ({
        ...sensor,
        isBundled: "yes",
        bundleID: newBundleID,
      }));

      const filteredGatewayData = gatewayData.filter(
        (item) => item.id !== selectedGateways[0].id
      );

      const filteredSensorData = sensorData.filter((item) =>
        selectedSensors.every((sensor) => sensor.id !== item.id)
      );

      const filteredBundleData = bundleData.filter(
        (item) => item.bundleID !== newBundleID
      );

      const newMergedData = [...filteredBundleData, newGateway, ...newSensors];

      setBundleData(newMergedData);
      setGatewayData(filteredGatewayData);
      setSensorData(filteredSensorData);

      setIsModalOpened(false);

      PopUpMessage({ type: "success", content: "Bundle is ready!" });
    } else {
      PopUpMessage({
        type: "warning",
        content: "Pick 1 gateway and 4 sensors!",
      });
    }
  };

  const handleExpand = (record) => {
    setExpandedRowKey((prevKey) => (prevKey === record.id ? null : record.id));
  };

  const expandedRowRender = (record) => (
    <div className="bundle__expand-container">
      <div className="bundle__expand-table">
        <h4 className="bundle__expand-table-header">Connected Gateway ID</h4>
        <li className="bundle__expand-table-item">
          {record.connectedGatewayID}
        </li>
      </div>
      <div className="bundle__expand-table">
        <h4 className="bundle__expand-table-header">Connected Sensor ID's</h4>
        {record.connectedSensorIDs.map((sensor) => (
          <li className="bundle__expand-table-item" key={sensor.id}>
            {sensor}
          </li>
        ))}
      </div>
    </div>
  );

  return (
    <div className="page-outer-container">
      <Row gutter={[16, 16]}>
        <Col xxl={9} xl={12} lg={24} md={24} sm={24}>
          <CustomCard title="Gateways">
            <CustomTable
              tableName={"Bundles"}
              columns={gatewayColumns}
              dataSource={gatewayData}
              setSelectedItems={setSelectedGateways}
              additionalButtons={
                <Link to={`/adddevice`} style={{ display: "flex", gap: 15 }}>
                  <Button
                    style={{
                      alignSelf: "flex-end",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Add Device
                  </Button>
                </Link>
              }
            />
          </CustomCard>
        </Col>
        <Col xxl={9} xl={12} lg={24} md={24} sm={24}>
          <CustomCard title="Sensors">
            <CustomTable
              tableName={"Bundles"}
              columns={sensorColumns}
              dataSource={sensorData}
              setSelectedItems={setSelectedSensors}
              additionalButtons={
                <Link to={`/adddevice`} style={{ display: "flex", gap: 15 }}>
                  <Button
                    style={{
                      alignSelf: "flex-end",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Add Device
                  </Button>
                </Link>
              }
            />
          </CustomCard>
        </Col>
        <Col xxl={6} xl={12} lg={24} md={24} sm={24}>
          <CustomCard title="Bundles">
            <CustomTable
              expandable={{
                expandedRowRender: (record) => expandedRowRender(record),
                defaultExpandedRowKeys: ["0"],
                expandedRowKeys: [expandedRowKey],
                onExpand: (expanded, record) => handleExpand(record),
              }}
              tableName={"Bundles"}
              columns={bundleColumns}
              dataSource={bundleData}
              setSelectedItems={setSelectedBundles}
              additionalButtons={
                <Button
                  style={{
                    alignSelf: "flex-end",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleMakeBundleButtonClick}
                >
                  Make Bundle
                </Button>
              }
            />
          </CustomCard>
        </Col>
      </Row>
      <Modal
        title="You are about to make a bundle!"
        centered
        open={isModalOpened}
        onOk={() => {
          setIsModalOpened(false);
          makeBundle();
          PopUpMessage({ type: "success", content: "Bundle is ready!" });
        }}
        onCancel={() => {
          setIsModalOpened(false);
          PopUpMessage({ type: "error", content: "Bundle didn't prepare!" });
        }}
      />
    </div>
  );
};
