import "./Warehouse.css";
import data from "../data.json";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Menu, Modal } from "antd";
import { WarningOutlined, DownOutlined } from "@ant-design/icons";
import { CustomTable } from "../components/CustomTable";
import { StatusIcons } from "../components/StatusIcons";
import { PopUpMessage } from "../components/PopUpMessage";

const columns = [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
    sorter: (a, b) => a.id < b.id,
  },
  {
    key: "type",
    dataIndex: "type",
    title: "Type",
    filters: [
      {
        text: "gateway",
        value: "gateway",
      },
      {
        text: "sensor",
        value: "sensor",
      },
    ],
    render: (text, record) => (
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        {StatusIcons[record.type]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.type.includes(value),
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    key: "inWhere",
    dataIndex: "inWhere",
    title: "Device Location",
    filters: [
      {
        text: "client",
        value: "client",
      },
      {
        text: "warehouse",
        value: "warehouse",
      },
    ],
    render: (text, record) => (
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        {StatusIcons[record.inWhere]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.inWhere.includes(value),
    sorter: (a, b) => a.inWhere.localeCompare(b.inWhere),
  },
  {
    key: "leaseType",
    dataIndex: "leaseType",
    title: "Lease Type",
    filters: [
      {
        text: "sold",
        value: "sold",
      },
      {
        text: "rented",
        value: "rented",
      },
    ],
    render: (text, record) => (
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        {StatusIcons[record.leaseType]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.leaseType === value,
    sorter: (a, b) => (a.leaseType ?? "").localeCompare(b.leaseType ?? ""),
  },
  {
    key: "isBundled",
    dataIndex: "isBundled",
    title: "Bundle Status",
    filters: [
      {
        text: "yes",
        value: "yes",
      },
      {
        text: "no",
        value: "no",
      },
    ],
    render: (text, record) => (
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        {StatusIcons[record.isBundled]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.isBundled.includes(value),
    sorter: (a, b) => a.isBundled.localeCompare(b.isBundled),
  },
  {
    key: "bundleID",
    dataIndex: "bundleID",
    title: "Bundle ID",
    render: (_, record) => {
      return record.bundleID ? (
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          {record.bundleID}
        </div>
      ) : (
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>-</div>
      );
    },
    sorter: (a, b) => {
      const valueA = a.bundleID === null ? 0 : a.bundleID;
      const valueB = b.bundleID === null ? 0 : b.bundleID;

      return valueA - valueB;
    },
  },
  {
    key: "stateTBD",
    dataIndex: "stateTBD",
    title: "stateTBD",
    width: window.innerWidth / 17,
    render: () => (
      <Link to="/users">
        <WarningOutlined style={{ color: "red", cursor: "pointer" }} />
      </Link>
    ),
  },
  {
    key: "ayarlar",
    dataIndex: "ayarlar",
    title: "Ayarlar",
    width: window.innerWidth / 17,
    render: (_, record) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={() => handleEdit(record)}>
              Edit
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleDelete(record)}>
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <p style={{ cursor: "pointer" }}>
          More <DownOutlined />
        </p>
      </Dropdown>
    ),
  },
];

const handleEdit = (record) => {};
const handleDelete = (record) => {};

export const Warehouse = () => {
  const [mergedData, setMergedData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);

  useEffect(() => {
    setMergedData([...data.gatewayData, ...data.sensorData]);
  }, []);

  const gatewayCount = selectedItems.filter(
    (item) => item.type === "gateway" && item.inWhere === "warehouse"
  ).length;
  const sensorCount = selectedItems.filter(
    (item) => item.type === "sensor" && item.inWhere === "warehouse"
  ).length;

  const handleMakeBundle = () => {
    if (gatewayCount === 1 && sensorCount === 4) {
      setIsModalOpened(true);
    } else {
      PopUpMessage({
        type: "warning",
        content: "Pick 1 gateway and 4 sensors!",
      });
    }
  };

  const PopUpModal = () => (
    <Modal
      title="You are about to make a bundle!"
      centered
      open={isModalOpened}
      onOk={() => {
        setIsModalOpened(false);
        makeBundle();
        PopUpMessage({ type: "success", content: "Bundle is ready!" });
      }}
      onCancel={() => {
        setIsModalOpened(false);
        PopUpMessage({ type: "error", content: "Bundle didn't prepare!" });
      }}
    >
      <p>Gateway Count: {gatewayCount}</p>
      <p>Sensor Count: {sensorCount}</p>
    </Modal>
  );

  const makeBundle = () => {
    const gateway = selectedItems.find(
      (item) => item.type === "gateway" && item.inWhere === "warehouse"
    );
    const sensors = selectedItems.filter(
      (item) => item.type === "sensor" && item.inWhere === "warehouse"
    );

    const bundleID = Math.floor(Math.random() * 1000);

    const newGateway = {
      ...gateway,
      isBundled: "yes",
      bundleID: bundleID,
    };
    const newSensors = sensors.map((sensor) => ({
      ...sensor,
      isBundled: "yes",
      bundleID: bundleID,
    }));

    const newMergedData = mergedData.map((item) => {
      if (item.id === newGateway.id) {
        return newGateway;
      } else if (newSensors.find((sensor) => sensor.id === item.id)) {
        return newSensors.find((sensor) => sensor.id === item.id);
      } else {
        return item;
      }
    });
    setMergedData(newMergedData);
  };

  return (
    <div className="page-outer-container">
      <CustomTable
        tableName={"GatewayList"}
        columns={columns}
        dataSource={mergedData}
        setSelectedItems={setSelectedItems}
        additionalButtons={
          <>
            <Button
              style={{
                alignSelf: "flex-end",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleMakeBundle}
            >
              Make Bundle
            </Button>
            <Link to={`/adddevice`} style={{ display: "flex", gap: 15 }}>
              <Button
                style={{
                  alignSelf: "flex-end",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Add Device
              </Button>
            </Link>
          </>
        }
      />
      {PopUpModal()}
    </div>
  );
};
