import data from "../data.json";
import { useState } from "react";
import { Dropdown, Menu } from "antd";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { CustomTable } from "../components/CustomTable";
import { StatusIcons } from "../components/StatusIcons";
import { Link } from "react-router-dom";
dayjs().utcOffset();

const columns = [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
  },
  {
    key: "username",
    dataIndex: "user.name",
    title: "Username",
    render: (_, record) => {
      const notificationOwner = data.userData.find(
        (user) => user.id === record.userID
      );

      return (
        <Link
          style={{ display: "flex", gap: 10 }}
          to={`/user/${notificationOwner.id}`}
        >
          <UserOutlined style={{ color: "green", cursor: "pointer" }} />
          <p style={{ color: "black" }}>{notificationOwner.username}</p>
        </Link>
      );
    },
  },
  {
    key: "date",
    dataIndex: "date",
    title: "Date",
    sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    render: (_, record) => (
      <div>
        <div>{dayjs(record.date).format("DD.MM.YYYY - HH:mm:ss")}</div>
      </div>
    ),
  },
  {
    key: "type",
    dataIndex: "type",
    title: "Type",
    filters: [
      {
        text: "error",
        value: "error",
      },
      {
        text: "join",
        value: "join",
      },
      {
        text: "connection",
        value: "connection",
      },
    ],
    render: (text, record) => (
      <div style={{ display: "flex", gap: 10 }}>
        {StatusIcons[record.type]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.type.includes(value),
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    key: "message",
    dataIndex: "message",
    title: "Message",
  },
  {
    key: "ayarlar",
    dataIndex: "ayarlar",
    title: "Ayarlar",
    width: window.innerWidth / 17,
    render: (_, record) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={() => handleEdit(record)}>
              Edit
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleDelete(record)}>
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <p style={{ cursor: "pointer" }}>
          More <DownOutlined />
        </p>
      </Dropdown>
    ),
  },
];

const handleEdit = (record) => {};
const handleDelete = (record) => {};

export const Notifications = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  return (
    <div className="page-outer-container">
      <CustomTable
        tableName={"Notifications"}
        columns={columns}
        dataSource={data.notificationsData}
        setSelectedItems={setSelectedItems}
      />
    </div>
  );
};
