import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  BarChartOutlined,
  UploadOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

const sidebarItems = [
  {
    key: "1",
    icon: <UploadOutlined />,
    label: "Home",
    url: "/",
  },
  {
    key: "2",
    icon: <BarChartOutlined />,
    label: "Fleet Management",
    url: "/fleet",
  },
  {
    key: "3",
    icon: <VideoCameraOutlined />,
    label: "Gateway List",
    url: "/gatewaylist",
  },
  {
    key: "4",
    icon: <UploadOutlined />,
    label: "Notifications",
    url: "/notifications",
  },
  {
    key: "5",
    icon: <VideoCameraOutlined />,
    label: "User List",
    url: "/userlist",
  },
  {
    key: "6",
    icon: <BarChartOutlined />,
    label: "Warehouse",
    url: "/warehouse",
  },
  {
    key: "7",
    icon: <BarChartOutlined />,
    label: "New Warehouse",
    url: "/newwarehouse",
  },
];

export const Sidebar = () => {
  return (
    <Layout hasSider>
      <Layout.Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          paddingTop: "2.5rem",
          background: "white",
        }}
      >
        <Menu
          style={{ background: "white" }}
          theme="light"
          mode="inline"
          defaultSelectedKeys={["1"]}
        >
          {sidebarItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.url}>{item.label}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Layout.Sider>
    </Layout>
  );
};
