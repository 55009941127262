import "./FleetManagement.css";
import data from "../data.json";
import { useState } from "react";
import { CustomCard } from "../components/CustomCard";
import { GatewayDrawer } from "../components/GatewayDrawer";
import { GatewayMap } from "../components/GatewayMap";
import { Col, Row, Drawer, Menu } from "antd";
import { StatusIcons } from "../components/StatusIcons";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";

const filterBarItems = {
  status: [
    { key: "101", icon: StatusIcons["all"], label: "all" },
    { key: "102", icon: StatusIcons["active"], label: "active" },
    { key: "103", icon: StatusIcons["inactive"], label: "inactive" },
    { key: "104", icon: StatusIcons["warning"], label: "warning" },
  ],
  leaseType: [
    { key: "201", icon: StatusIcons["sold"], label: "sold" },
    { key: "202", icon: StatusIcons["rented"], label: "rented" },
  ],
};

export const FleetManagement = () => {
  const clientGateways = data.gatewayData.filter(
    (item) => item.inWhere === "client"
  );

  const [open, setOpen] = useState(false);
  const [currentContent, setCurrentContent] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [filteredData, setFilteredData] = useState(clientGateways);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [gatewaySearchValue, setGatewaySearchValue] = useState("");
  const [searched, setSearched] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("101");

  const handleFilter = (filter, propertyToFilter, selectedKey) => {
    let filteredData =
      filter === "all"
        ? clientGateways
        : clientGateways.filter((item) => item[propertyToFilter] === filter);

    setFilteredData(filteredData);
    setSelectedFilter(selectedKey);
  };

  const handleGatewayClick = (content) => {
    setOpen(true);
    setCurrentContent(content);
    setMapCenter([content.lng, content.lat]);
    setSelectedGateway(content);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    setMapCenter(null);
    setSelectedGateway(null);
  };

  const handleSearch = () => {
    if (gatewaySearchValue.length > 0) {
      let newSearch = filteredData.filter(
        (item) =>
          item.name.toLowerCase().includes(gatewaySearchValue.toLowerCase()) ||
          item.id.toString().includes(gatewaySearchValue)
      );

      setSearched(!searched);
      setFilteredData(newSearch);
    }
  };

  const handleSearchClose = () => {
    setGatewaySearchValue("");
    setFilteredData(clientGateways);
    setSearched(!searched);
    setSelectedFilter("101");
  };

  return (
    <div className="gateways__outer">
      <div className="gateways__gateway-list-container">
        <div className="gateways__gateway-search-container">
          <input
            className="gateways__gateway-search-input"
            placeholder="Search Gateway"
            value={gatewaySearchValue}
            onChange={(e) => setGatewaySearchValue(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSearch()}
          />
          {!searched ? (
            <SearchOutlined
              className="gateways__gateway-search-icon"
              onClick={handleSearch}
            />
          ) : (
            <CloseOutlined
              className="gateways__gateway-search-icon"
              onClick={handleSearchClose}
            />
          )}
          <Menu
            className="gateways__gateway-filter-bar"
            mode="horizontal"
            defaultSelectedKeys={["101"]}
            selectedKeys={[selectedFilter]}
          >
            {filterBarItems.status.map((item) => (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                style={{ display: "flex" }}
                onClick={() => handleFilter(item.label, "status", item.key)}
              >
                <p>{item.label}</p>
              </Menu.Item>
            ))}
            {filterBarItems.leaseType.map((item) => (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                style={{ display: "flex" }}
                onClick={() => handleFilter(item.label, "leaseType", item.key)}
              >
                <p>{item.label}</p>
              </Menu.Item>
            ))}
          </Menu>
        </div>
        <div className="gateways__gateway-list">
          {filteredData.map((item, index) =>
            item.inWhere === "client" ? (
              <div
                className={`gateways__gateway-row${
                  selectedGateway === item ? " selected" : ""
                }`}
                key={index}
                onClick={() => handleGatewayClick(item)}
              >
                <div className="gateway-info-left">
                  {StatusIcons[item.status]}
                  <div className="gateways__gateway-text-container-left">
                    <p className="gateways__gateway-header">Gateway Adı</p>
                    <p className="gateway-text">{item.name}</p>
                  </div>
                </div>
                <div className="gateways__gateway-text-container-right">
                  <p className="gateways__gateway-header">Gateway ID</p>
                  <p className="gateway-text">{item.id}</p>
                </div>
              </div>
            ) : null
          )}
        </div>
        <Drawer
          title={"Gateway Details"}
          placement={"left"}
          width={415}
          closable={true}
          onClose={handleCloseDrawer}
          mask={false}
          open={open}
          key={1}
          contentWrapperStyle={{
            top: "2.5rem",
            marginLeft: 470,
          }}
          headerStyle={{ paddingRight: "0", paddingLeft: "10px" }}
          bodyStyle={{ padding: 0 }}
        >
          <GatewayDrawer content={currentContent} />
        </Drawer>
      </div>
      <div className="gateways__map-container">
        <GatewayMap
          filteredData={filteredData}
          mapCenter={mapCenter && mapCenter}
        />
        <div
          className="gateways__info-container"
          style={{ width: selectedGateway ? "calc(100% - 415px)" : "100%" }}
        >
          <Row gutter={[16, 16]}>
            {Object.keys(filterBarItems).map((category) =>
              filterBarItems[category].map((item) => (
                <Col span={4} key={item.key}>
                  <CustomCard
                    addedClassName={`gateways__info-box-card`}
                    onClick={() => handleFilter(item.label, category, item.key)}
                    addedBodyStyle={{
                      cursor: "pointer",
                      height: 20,
                      padding: 14,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: `rgba(0, 0, 0, 0.25) 0px 54px 55px,
                        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
                        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px`,
                    }}
                  >
                    <p className="gateways__info-box-text">
                      {item.icon}
                      {`${item.label}: ${
                        item.label === "all"
                          ? clientGateways.length
                          : item.label === "sold" || item.label === "rented"
                          ? clientGateways.filter(
                              (gateway) => gateway.leaseType === item.label
                            ).length
                          : clientGateways.filter(
                              (gateway) => gateway.status === item.label
                            ).length
                      }`}
                    </p>
                  </CustomCard>
                </Col>
              ))
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};
