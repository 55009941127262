import { useState } from "react";
import { Button, Col, Form, Input, Radio, Row } from "antd";
import { CustomCard } from "../components/CustomCard";
import { PopUpMessage } from "../components/PopUpMessage";
import sensor from "../assets/device_images/sensor.png";
import gateway from "../assets/device_images/gateway.png";

export const AddDevice = () => {
  const [form] = Form.useForm();
  const [deviceType, setDeviceType] = useState(gateway);

  const handleFormSubmit = (values, form) => {
    if (!values.parentGatewayThingId) {
      values.parentGatewayThingId = "123456789"; // default fake value
    }
    console.log("Form submitted!", values);
    form.resetFields();
    PopUpMessage({ type: "success", content: "Device added!" });
  };

  return (
    <div className="page-outer-container">
      <Row gutter={[16, 16]}>
        <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
          <CustomCard title="Add Device">
            <img src={deviceType} alt="deviceType" style={{ height: 200 }} />
            <Form
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              layout="horizontal"
              style={{ maxWidth: 600, textAlign: "left" }}
              onFinish={(values) => handleFormSubmit(values, form)}
              onFinishFailed={() =>
                PopUpMessage({
                  type: "error",
                  content: "Cannot add device!",
                })
              }
              initialValues={{ type: "gateway" }}
            >
              <Form.Item label="type" name="type" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio onClick={() => setDeviceType(gateway)} value="gateway">
                    gateway
                  </Radio>
                  <Radio onClick={() => setDeviceType(sensor)} value="sensor">
                    sensor
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                hasFeedback
                label="devSerial"
                name="devSerial"
                rules={[{ len: 16 }]}
              >
                <Input maxLength={16} minLength={16} />
              </Form.Item>
              <Form.Item
                hasFeedback
                label="devEui"
                name="devEui"
                rules={[{ len: 16, required: true }]}
              >
                <Input maxLength={16} minLength={16} />
              </Form.Item>
              <Form.Item
                hasFeedback
                label="name"
                name="name"
                rules={[{ required: true }]}
              >
                <Input.TextArea autoSize style={{ resize: "none" }} />
              </Form.Item>
              <Form.Item hasFeedback label="description" name="description">
                <Input.TextArea autoSize style={{ resize: "none" }} />
              </Form.Item>
              <Form.Item
                hasFeedback
                label="parentGatewayThingId"
                name="parentGatewayThingId"
              >
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#154a45" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </CustomCard>
        </Col>
      </Row>
    </div>
  );
};
