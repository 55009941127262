import dayjs from "dayjs";
import axios from "axios";
export const url = "https://server11.mikroterratechnologies.com:3443";

export const CalculatePercentage = (startDate, endDate) => {
  const now = dayjs();
  const total = endDate.diff(startDate, "day");
  const passed = now.diff(startDate, "day");
  return +Math.min((passed / total) * 100, 100).toFixed();
};

export function ShortenText(text, maxLength) {
  if (text?.length > maxLength) {
    if (text[maxLength - 1] === " ") {
      return text.substring(0, maxLength - 1) + "...";
    } else {
      return text.substring(0, maxLength) + "...";
    }
  }
  return text;
}

export async function tR(endpoint, method, data) {
  try {
    const res = await axios({
      method: method,
      url: url + endpoint,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      timeout: 90000,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response.status === 401) {
      try {
        const res = await axios.post(url + "/auth/refresh-token", {
          refreshToken: localStorage.getItem("refreshToken"),
        });
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        const res2 = await axios({
          method: method,
          url: url + endpoint,
          data: data,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        return res2.data;
      } catch (err) {
        if (err.response.status === 401) {
          console.log("refresh token expired");
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/";
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/";
        }
      }
    } else {
    }
  }
}

export async function tU(endpoint, data) {
  try {
    const res = await axios({
      method: "POST",
      url: url + endpoint,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response.status === 401) {
      try {
        const res = await axios.post(url + "/auth/refresh-token", {
          refreshToken: localStorage.getItem("refreshToken"),
        });
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        const res2 = await axios({
          method: "POST",
          url: url + endpoint,
          data: data,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        return res2.data;
      } catch (err) {
        if (err.response.status === 401) {
          console.log("refresh token expired");
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/";
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/";
        }
      }
    } else {
    }
  }
}
