import data from "../data.json";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { CustomTable } from "../components/CustomTable";
import { useState } from "react";
import { Button } from "antd";

const columns = [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Name",
  },
  {
    key: "username",
    dataIndex: "username",
    title: "Username",
  },
  {
    title: "Company",
    dataIndex: "company",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  // {
  //   key: "profil",
  //   dataIndex: "profil",
  //   title: "Profil",
  //   width: window.innerWidth / 17,
  //   render: (_, record) => (
  //     <Link to={`/user/${record.id}`}>
  //       <UserOutlined style={{ color: "green" }} />
  //     </Link>
  //   ),
  // },
];

export const UserList = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  return (
    <div className="page-outer-container">
      <CustomTable
        tableName={"UserList"}
        columns={columns}
        dataSource={data.userData}
        setSelectedItems={setSelectedItems}
        additionalButtons={
          <Link to={`/registeruser`} style={{ display: "flex", gap: 15 }}>
            <Button
              style={{
                alignSelf: "flex-end",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Register User
            </Button>
          </Link>
        }
      />
    </div>
  );
};
