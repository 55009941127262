import "./GatewayDrawer.css";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import { InsuranceOutlined, UserOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import data from "../data.json";

export const GatewayDrawer = ({ content }) => {
  const attachedSensors = data.sensorData.filter((sensor) =>
    content.connectedSensorIDs.includes(sensor.id)
  );

  const gatewayOwner = data.userData.find((user) => user.id === content.userID);

  return (
    <div className="drawer__outer">
      <div className="drawer__info-container">
        <div className="drawer__info-left">
          <p>{content.name}</p>
          <div className="drawer__info-bottom-text">
            <p className="drawer__info-id">{content.id}</p>
            <p
              className="drawer__info-activity-text"
              style={{
                color:
                  content.status === "active"
                    ? "green"
                    : content.status === "inactive"
                    ? "red"
                    : "orange",
              }}
            >
              {content.status}
            </p>
          </div>
        </div>
        <Link to={`/user/${content.userID}`}>
          <Avatar
            style={{
              backgroundColor: "#87d068",
              cursor: "pointer",
            }}
            icon={<UserOutlined />}
          />
        </Link>
      </div>
      <div className="drawer__user-container">
        <div className="drawer__user-row">
          <div className="drawer__user-info">
            <p className="drawer__header">Kullanıcı Adı</p>
            <p className="drawer__text">{gatewayOwner.name}</p>
          </div>
          <div className="drawer__user-info">
            <p className="drawer__header">E-mail</p>
            <p className="drawer__text">{gatewayOwner.email}</p>
          </div>
        </div>
        <div className="drawer__user-row">
          <div className="drawer__user-info">
            <p className="drawer__header">Telefon</p>
            <p className="drawer__text">{gatewayOwner.phone}</p>
          </div>
          <div className="drawer__user-info">
            <p className="drawer__header">Şirket</p>
            <p className="drawer__text">{gatewayOwner.company}</p>
          </div>
        </div>
        <div className="drawer__user-row">
          <div className="drawer__user-info">
            <p className="drawer__header">Adres</p>
            <p className="drawer__text">{gatewayOwner.address}</p>
          </div>
          <div className="drawer__user-info">
            <p className="drawer__header">Kimlik No</p>
            <p className="drawer__text">{gatewayOwner.id}</p>
          </div>
        </div>
      </div>
      <div className="drawer__subscription-container">
        <div className="drawer__subscription-box">
          <p className="drawer__header">Üyelik Başlangıç Tarihi</p>
          <p className="drawer__text">
            {dayjs(gatewayOwner.subStartDate).format("DD/MM/YYYY")}
          </p>
        </div>
        <div className="drawer__subscription-box">
          <p className="drawer__header">Üyelik Bitiş Tarihi</p>
          <p className="drawer__text">
            {dayjs(gatewayOwner.subEndDate).format("DD/MM/YYYY")}
          </p>
        </div>
      </div>
      <div className="drawer__sensor-list">
        {attachedSensors.map((sensor, index) => (
          <div className="drawer__sensor-row" key={index}>
            <div className="drawer__sensor-info-left">
              <InsuranceOutlined
                style={{
                  color:
                    sensor.status === "active"
                      ? "green"
                      : sensor.status === "inactive"
                      ? "red"
                      : "orange",
                }}
              />
              <div className="drawer__sensor-text-container">
                <p className="drawer__header">Sensör Adı</p>
                <p className="drawer__text">{sensor.name}</p>
              </div>
            </div>
            <div className="drawer__sensor-text-container-right">
              <p className="drawer__header">Sensör ID</p>
              <p className="drawer__text">{sensor.id}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
