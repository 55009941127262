import { Card } from "antd";

export const CustomCard = ({
  onClick,
  title,
  children,
  addedClassName,
  addedBodyStyle,
}) => {
  return (
    <Card
      onClick={onClick}
      title={title}
      headStyle={{
        minHeight: 36,
        background: `linear-gradient(125deg, #F7E9E8 0%, #99ACEB88`,
      }}
      style={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.35)", height: "100%" }}
      className={addedClassName}
      bodyStyle={{ ...addedBodyStyle }}
    >
      {children}
    </Card>
  );
};
