import "./Home.css";
import data from "../data.json";
import { useEffect, useState } from "react";
import { CustomCard } from "../components/CustomCard";
import { PieChart, Pie, Cell, Sector, Tooltip } from "recharts";
import { Col, Row } from "antd";

const pieChartColors = [
  `url(#gradient2)`,
  "url(#gradient1)",
  `url(#gradient3)`,
  `url(#gradient4)`,
  `url(#gradient5)`,
];
export const Home = () => {
  const [gatewayData, setGatewayData] = useState([]);
  const [sensorData, setSensorData] = useState([]);
  const [activeIndex1, setActiveIndex1] = useState(0);
  const [activeIndex2, setActiveIndex2] = useState(0);
  const [activeIndex3, setActiveIndex3] = useState(0);
  const [activeIndex4, setActiveIndex4] = useState(0);
  const [activeIndex5, setActiveIndex5] = useState(0);
  const [activeIndex6, setActiveIndex6] = useState(0);
  const [activeIndex7, setActiveIndex7] = useState(0);

  useEffect(() => {
    setGatewayData(data.gatewayData);
    setSensorData(data.sensorData);
  }, []);

  const allGateways = gatewayData.length;

  const soldGateways = gatewayData.filter(
    (gateway) => gateway.leaseType === "sold"
  ).length;
  const rentedGateways = gatewayData.filter(
    (gateway) => gateway.leaseType === "rented"
  ).length;
  const stockedGateways = gatewayData.filter(
    (gateway) => gateway.inWhere === "warehouse"
  ).length;

  const activeGateways = gatewayData.filter(
    (gateway) => gateway.status === "active"
  ).length;
  const inactiveGateways = gatewayData.filter(
    (gateway) => gateway.status === "inactive"
  ).length;
  const warningGateways = gatewayData.filter(
    (gateway) => gateway.status === "warning"
  ).length;

  const allSoldGateways = gatewayData.filter(
    (gateway) => gateway.leaseType === "sold"
  ).length;
  const soldActiveGateways = gatewayData.filter(
    (gateway) => gateway.leaseType === "sold" && gateway.status === "active"
  ).length;
  const soldInactiveGateways = gatewayData.filter(
    (gateway) => gateway.leaseType === "sold" && gateway.status === "inactive"
  ).length;
  const soldWarningGateways = gatewayData.filter(
    (gateway) => gateway.leaseType === "sold" && gateway.status === "warning"
  ).length;

  const allRentedGateways = gatewayData.filter(
    (gateway) => gateway.leaseType === "rented"
  ).length;
  const rentedActiveGateways = gatewayData.filter(
    (gateway) => gateway.leaseType === "rented" && gateway.status === "active"
  ).length;
  const rentedInactiveGateways = gatewayData.filter(
    (gateway) => gateway.leaseType === "rented" && gateway.status === "inactive"
  ).length;
  const rentedWarningGateways = gatewayData.filter(
    (gateway) => gateway.leaseType === "rented" && gateway.status === "warning"
  ).length;

  const bundles = data.bundleData.filter(
    (bundle) => bundle.inWhere === "warehouse"
  ).length;

  const allSensors = sensorData.length;
  const soldSensors = gatewayData
    .filter(
      (gateway) =>
        gateway.leaseType === "sold" && gateway.connectedSensorIDs.length > 0
    )
    .map((gateway) => gateway.connectedSensorIDs.length)
    .reduce((a, b) => a + b, 0);
  const rentedSensors = gatewayData
    .filter(
      (gateway) =>
        gateway.leaseType === "rented" && gateway.connectedSensorIDs.length > 0
    )
    .map((gateway) => gateway.connectedSensorIDs.length)
    .reduce((a, b) => a + b, 0);
  const stockedSensors = sensorData.filter(
    (sensor) => sensor.inWhere === "warehouse"
  ).length;

  const activeSensors = sensorData.filter(
    (sensor) => sensor.status === "active"
  ).length;
  const inactiveSensors = sensorData.filter(
    (sensor) => sensor.status === "inactive"
  ).length;
  const warningSensors = sensorData.filter(
    (sensor) => sensor.status === "warning"
  ).length;

  const pieTotalGateways = [
    { name: "sold", value: soldGateways },
    { name: "rented", value: rentedGateways },
    { name: "stocked", value: stockedGateways },
  ];
  const pieTotalGatewayStatuses = [
    { name: "active", value: activeGateways },
    { name: "inactive", value: inactiveGateways },
    { name: "warning", value: warningGateways },
  ];
  const pieSoldGatewayStatuses = [
    { name: "active", value: soldActiveGateways },
    { name: "inactive", value: soldInactiveGateways },
    { name: "warning", value: soldWarningGateways },
  ];
  const pieRentedGatewayStatuses = [
    { name: "active", value: rentedActiveGateways },
    { name: "inactive", value: rentedInactiveGateways },
    { name: "warning", value: rentedWarningGateways },
  ];
  const stockedDevices = [
    { name: "gateway", value: stockedGateways },
    { name: "sensor", value: stockedSensors },
  ];

  const pieTotalSensors = [
    { name: "sold", value: soldSensors },
    { name: "rented", value: rentedSensors },
    { name: "stocked", value: stockedSensors },
  ];
  const pieTotalSensorStatuses = [
    { name: "active", value: activeSensors },
    { name: "inactive", value: inactiveSensors },
    { name: "warning", value: warningSensors },
  ];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
    const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

    return percent > 0 ? (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    ) : null;
  };

  const renderActiveShape = ({
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  }) => {
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <div className="page-outer-container" style={{ gap: 16 }}>
      <Row gutter={[16, 16]}>
        <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
          <CustomCard title="Total Gateways">
            <div className="stock__gateway-pie-container">
              <PieChart width={300} height={300}>
                <Pie
                  activeIndex={activeIndex1}
                  activeShape={renderActiveShape}
                  onMouseEnter={(_, index) => setActiveIndex1(index)}
                  data={pieTotalGateways}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  dataKey="value"
                >
                  {pieTotalGateways.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieChartColors[index % pieChartColors.length]}
                    />
                  ))}
                </Pie>
                <defs>
                  <linearGradient
                    id="gradient1"
                    x1="25%"
                    y1="50%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#13c5a9 " />
                    <stop offset="100%" stopColor="#154a4a " />
                  </linearGradient>
                  <linearGradient
                    id="gradient2"
                    x1="25%"
                    y1="25%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#ffaa00" />
                    <stop offset="100%" stopColor="#e3c082" />
                  </linearGradient>
                  <linearGradient
                    id="gradient3"
                    x1="0%"
                    y1="25%"
                    x2="0%"
                    y2="75%"
                  >
                    <stop offset="0%" stopColor="#ec1818" />
                    <stop offset="100%" stopColor="#dd7878" />
                  </linearGradient>
                  <linearGradient
                    id="gradient4"
                    x1="25%"
                    y1="50%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#8B4513" />
                    <stop offset="100%" stopColor="#CD853F" />
                  </linearGradient>
                  <linearGradient
                    id="gradient5"
                    x1="25%"
                    y1="50%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#269798" />
                    <stop offset="100%" stopColor="#ff7ae6" />
                  </linearGradient>
                </defs>
                <Tooltip />
              </PieChart>
              <div className="stock__gateway-list">
                <div className="stock__gateway-list-item">
                  <p className="stock__gateway-header">Total Gateways</p>
                  <p className="stock__gateway-text">{allGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "orange" }}
                >
                  <p className="stock__gateway-header">Sold Gateways</p>
                  <p className="stock__gateway-text">{soldGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#269798" }}
                >
                  <p className="stock__gateway-header">Rented Gateways</p>
                  <p className="stock__gateway-text">{rentedGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#ec1818" }}
                >
                  <p className="stock__gateway-header">Stocked Gateways</p>
                  <p className="stock__gateway-text">{stockedGateways}</p>
                </div>
              </div>
            </div>
          </CustomCard>
        </Col>
        <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
          <CustomCard title="Gateway Statuses">
            <div className="stock__gateway-pie-container">
              <PieChart width={300} height={300}>
                <Pie
                  activeIndex={activeIndex3}
                  activeShape={renderActiveShape}
                  onMouseEnter={(_, index) => setActiveIndex3(index)}
                  data={pieTotalGatewayStatuses}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  dataKey="value"
                >
                  {pieTotalGatewayStatuses.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieChartColors[index % pieChartColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
              <div className="stock__gateway-list">
                <div className="stock__gateway-list-item">
                  <p className="stock__gateway-header">Total Gateways</p>
                  <p className="stock__gateway-text">{allGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "orange" }}
                >
                  <p className="stock__gateway-header">Active Gateways</p>
                  <p className="stock__gateway-text">{activeGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#269798" }}
                >
                  <p className="stock__gateway-header">Inactive Gateways</p>
                  <p className="stock__gateway-text">{inactiveGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#ec1818" }}
                >
                  <p className="stock__gateway-header">Warning Gateways</p>
                  <p className="stock__gateway-text">{warningGateways}</p>
                </div>
              </div>
            </div>
          </CustomCard>
        </Col>
        <Col xxl={8} xl={24} lg={24} md={24} sm={24}>
          <CustomCard title="Stocked Devices">
            <div className="stock__gateway-pie-container">
              <PieChart width={300} height={300}>
                <Pie
                  activeIndex={activeIndex5}
                  activeShape={renderActiveShape}
                  onMouseEnter={(_, index) => setActiveIndex5(index)}
                  data={stockedDevices}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  dataKey="value"
                >
                  {stockedDevices.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieChartColors[index % pieChartColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
              <div className="stock__gateway-list">
                <div className="stock__gateway-list-item">
                  <p className="stock__gateway-header">Total Stocked</p>
                  <p className="stock__gateway-text">{stockedGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "orange" }}
                >
                  <p className="stock__gateway-header">Stocked Gateways</p>
                  <p className="stock__gateway-text">{stockedGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#269798" }}
                >
                  <p className="stock__gateway-header">Stocked Sensors</p>
                  <p className="stock__gateway-text">{stockedSensors}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#ec1818" }}
                >
                  <p className="stock__gateway-header">Stocked Bundles</p>
                  <p className="stock__gateway-text">{bundles}</p>
                </div>
              </div>
            </div>
          </CustomCard>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
          <CustomCard title="Sold Gateway Statuses">
            <div className="stock__gateway-pie-container">
              <PieChart width={300} height={300}>
                <Pie
                  activeIndex={activeIndex2}
                  activeShape={renderActiveShape}
                  onMouseEnter={(_, index) => setActiveIndex2(index)}
                  data={pieSoldGatewayStatuses}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  dataKey="value"
                >
                  {pieSoldGatewayStatuses.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieChartColors[index % pieChartColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
              <div className="stock__gateway-list">
                <div className="stock__gateway-list-item">
                  <p className="stock__gateway-header">Total Sold Gateways</p>
                  <p className="stock__gateway-text">{allSoldGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "orange" }}
                >
                  <p className="stock__gateway-header">Active Sold Gateways</p>
                  <p className="stock__gateway-text">{soldActiveGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#269798" }}
                >
                  <p className="stock__gateway-header">
                    Inactive Sold Gateways
                  </p>
                  <p className="stock__gateway-text">{soldInactiveGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#ec1818" }}
                >
                  <p className="stock__gateway-header">Warning Sold Gateways</p>
                  <p className="stock__gateway-text">{soldWarningGateways}</p>
                </div>
              </div>
            </div>
          </CustomCard>
        </Col>
        <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
          <CustomCard title="Rented Gateway Statuses">
            <div className="stock__gateway-pie-container">
              <PieChart width={300} height={300}>
                <Pie
                  activeIndex={activeIndex4}
                  activeShape={renderActiveShape}
                  onMouseEnter={(_, index) => setActiveIndex4(index)}
                  data={pieRentedGatewayStatuses}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  dataKey="value"
                >
                  {pieRentedGatewayStatuses.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieChartColors[index % pieChartColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
              <div className="stock__gateway-list">
                <div className="stock__gateway-list-item">
                  <p className="stock__gateway-header">Total Rented Gateways</p>
                  <p className="stock__gateway-text">{allRentedGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "orange" }}
                >
                  <p className="stock__gateway-header">
                    Active Rented Gateways
                  </p>
                  <p className="stock__gateway-text">{rentedActiveGateways}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#269798" }}
                >
                  <p className="stock__gateway-header">
                    Inactive Rented Gateways
                  </p>
                  <p className="stock__gateway-text">
                    {rentedInactiveGateways}
                  </p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#ec1818" }}
                >
                  <p className="stock__gateway-header">
                    Warning Rented Gateways
                  </p>
                  <p className="stock__gateway-text">{rentedWarningGateways}</p>
                </div>
              </div>
            </div>
          </CustomCard>
        </Col>
        <Col xxl={8} xl={24} lg={24} md={24} sm={24}>
          <CustomCard title=""></CustomCard>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
          <CustomCard title="Total Sensors">
            <div className="stock__gateway-pie-container">
              <PieChart width={300} height={300}>
                <Pie
                  activeIndex={activeIndex6}
                  activeShape={renderActiveShape}
                  onMouseEnter={(_, index) => setActiveIndex6(index)}
                  data={pieTotalSensors}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  dataKey="value"
                >
                  {pieTotalSensors.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieChartColors[index % pieChartColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
              <div className="stock__gateway-list">
                <div className="stock__gateway-list-item">
                  <p className="stock__gateway-header">Total Sensors</p>
                  <p className="stock__gateway-text">{allSensors}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "orange" }}
                >
                  <p className="stock__gateway-header">Sold Sensors</p>
                  <p className="stock__gateway-text">{soldSensors}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#269798" }}
                >
                  <p className="stock__gateway-header">Rented Sensors</p>
                  <p className="stock__gateway-text">{rentedSensors}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#ec1818" }}
                >
                  <p className="stock__gateway-header">Stocked Sensors</p>
                  <p className="stock__gateway-text">{stockedSensors}</p>
                </div>
              </div>
            </div>
          </CustomCard>
        </Col>
        <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
          <CustomCard title="Sensor Statuses">
            <div className="stock__gateway-pie-container">
              <PieChart width={300} height={300}>
                <Pie
                  activeIndex={activeIndex7}
                  activeShape={renderActiveShape}
                  onMouseEnter={(_, index) => setActiveIndex7(index)}
                  data={pieTotalSensorStatuses}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  dataKey="value"
                >
                  {pieTotalSensorStatuses.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieChartColors[index % pieChartColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
              <div className="stock__gateway-list">
                <div className="stock__gateway-list-item">
                  <p className="stock__gateway-header">Total Sensors</p>
                  <p className="stock__gateway-text">{allSensors}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "orange" }}
                >
                  <p className="stock__gateway-header">Active Sensors</p>
                  <p className="stock__gateway-text">{activeSensors}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#269798" }}
                >
                  <p className="stock__gateway-header">Inactive Sensors</p>
                  <p className="stock__gateway-text">{inactiveSensors}</p>
                </div>
                <div
                  className="stock__gateway-list-item"
                  style={{ color: "#ec1818" }}
                >
                  <p className="stock__gateway-header">Warning Sensors</p>
                  <p className="stock__gateway-text">{warningSensors}</p>
                </div>
              </div>
            </div>
          </CustomCard>
        </Col>
        <Col xxl={8} xl={24} lg={24} md={24} sm={24}>
          <CustomCard title=""></CustomCard>
        </Col>
      </Row>
    </div>
  );
};
