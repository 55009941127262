import {
  CheckCircleTwoTone,
  WarningTwoTone,
  ApiTwoTone,
  DollarTwoTone,
  PlusCircleTwoTone,
  ThunderboltTwoTone,
  HomeTwoTone,
  StarFilled,
  UserOutlined,
} from "@ant-design/icons";

export const StatusIcons = {
  all: <StarFilled twoToneColor="#52c41a" />,
  active: <CheckCircleTwoTone twoToneColor="#52c41a" />,
  warning: <WarningTwoTone twoToneColor="#fca510" />,
  inactive: <ApiTwoTone twoToneColor="#FF0000" />,
  sold: <DollarTwoTone twoToneColor="#52c41a" />,
  rented: <HomeTwoTone twoToneColor="#fca510" />,
  error: <WarningTwoTone twoToneColor="#ff0000" />,
  join: <PlusCircleTwoTone twoToneColor="#52c41a" />,
  connection: <ThunderboltTwoTone twoToneColor="#fca510" />,
  sensor: <HomeTwoTone twoToneColor="#fca510" />,
  gateway: <StarFilled twoToneColor="#acacff" />,
  client: <UserOutlined twoToneColor="blue" />,
  warehouse: <HomeTwoTone twoToneColor="red" />,
  no: <PlusCircleTwoTone twoToneColor="red" />,
  yes: <CheckCircleTwoTone twoToneColor="#52c41a" />,
};
