import "./ResetPassword.css";

export const ResetPassword = ({ resetPassword, username }) => {
  const submitForm = async (e) => {
    e.preventDefault();
    const loginUsername = document.getElementById("reset-email");
    const currentPassword = document.getElementById("current-password");
    const newPassword = document.getElementById("new-password");
    const newPassword2 = document.getElementById("new-password2");

    const submitObject = {
      loginUsername: loginUsername.value,
      currentPassword: currentPassword.value,
      newPassword: newPassword.value,
      newPassword2: newPassword2.value,
    };

    resetPassword(submitObject);
  };

  return (
    <div className="reset-container">
      <div className="reset-box">
        <p className="reset-cc-text">Reset Password</p>
        <form className="reset-form" onSubmit={submitForm} id="reset-form">
          <div className="reset-pw-input-div">
            <label className="reset-pw-label" htmlFor="reset-email">
              User
            </label>
            <input
              className="reset-input"
              type="text"
              id="reset-email"
              name="reset-email"
              placeholder="E-postanız"
              defaultValue={username}
            />
          </div>
          <div className="reset-pw-input-div">
            <label className="reset-pw-label" htmlFor="current-password">
              Current Password
            </label>
            <input
              className="reset-input"
              type="password"
              id="current-password"
              name="current-password"
              placeholder="Current Password"
            />
          </div>
          <div className="reset-pw-input-div">
            <label className="reset-pw-label" htmlFor="new-password">
              New Password
            </label>
            <input
              className="reset-input"
              type="password"
              id="new-password"
              name="new-password"
              placeholder="New Password"
            />
          </div>
          <div className="reset-pw-input-div">
            <label className="reset-pw-label" htmlFor="new-password2">
              Confirm Password
            </label>
            <input
              className="reset-input"
              type="password"
              id="new-password2"
              name="new-password2"
              placeholder="Confirm Password"
            />
          </div>
          <button className="reset-button" type="submit" onClick={submitForm}>
            Şifreyi Değiştir
          </button>
        </form>
      </div>
    </div>
  );
};
