import "./User.css";
import data from "../data.json";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomCard } from "../components/CustomCard";
import { GatewayMap } from "../components/GatewayMap";
import { Button, Col, Input, Row, DatePicker, Tooltip, Select } from "antd";
import { SmileTwoTone } from "@ant-design/icons";
import { CustomTable } from "../components/CustomTable";
import { CalculatePercentage, ShortenText } from "../components/functions";
import { StatusIcons } from "../components/StatusIcons";
import { PieChart, Pie, Cell } from "recharts";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const columns = [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
  },
  {
    key: "username",
    dataIndex: "user.name",
    title: "Username",
    render: (_, record) => {
      const notificationOwner = data.userData.find(
        (user) => user.id === record.userID
      );
      return (
        <div style={{ display: "flex", gap: 10 }}>
          {notificationOwner.username}
        </div>
      );
    },
  },
  {
    key: "type",
    dataIndex: "type",
    title: "Type",
    filters: [
      {
        text: "error",
        value: "error",
      },
      {
        text: "join",
        value: "join",
      },
      {
        text: "connection",
        value: "connection",
      },
    ],
    render: (text, record) => (
      <div style={{ display: "flex", gap: 10 }}>
        {StatusIcons[record.type]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.type.includes(value),
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    key: "message",
    dataIndex: "message",
    title: "Message",
    render: (_, record) => (
      <Tooltip placement="bottomLeft" title={record.message}>
        {ShortenText(record.message, 20)}
      </Tooltip>
    ),
  },
  {
    key: "date",
    dataIndex: "date",
    title: "Date",
    sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    render: (_, record) => (
      <div>
        <div>{dayjs(record.date).format("DD.MM.YYYY - HH:mm:ss")}</div>
      </div>
    ),
  },
];

const subOptions = [
  {
    value: "Free",
    label: "Free",
  },
  {
    value: "Trial",
    label: "Trial",
  },
  {
    value: "Plus",
    label: "Plus",
  },
  {
    value: "Premium",
    label: "Premium",
  },
];

const badgeOptions = [
  {
    value: "Bronze",
    label: "Bronze",
  },
  {
    value: "Silver",
    label: "Silver",
  },
  {
    value: "Gold",
    label: "Gold",
  },
  {
    value: "Platinum",
    label: "Platinum",
  },
  {
    value: "Diamond",
    label: "Diamond",
  },
];

const generateInput = (editMode, value, onChange) =>
  editMode ? (
    <Input
      className="user__edit-text"
      type="text"
      value={value}
      onChange={onChange}
    />
  ) : (
    <p className="user__text">{value}</p>
  );

const generateUserDetailBox = (label, valueElement) => (
  <div className="user__box">
    <SmileTwoTone />
    <div className="user__text-box">
      <p className="user__header">{label}</p>
      {valueElement}
    </div>
  </div>
);

const generateDatePicker = (
  editMode,
  date,
  onChange,
  placeholder,
  minimumDate
) => {
  if (editMode) {
    return (
      <DatePicker
        format={"DD.MM.YYYY"}
        style={{ width: "100%" }}
        onChange={onChange}
        placeholder={placeholder}
        disabledDate={(current) =>
          current &&
          dayjs(current).toISOString() < dayjs(minimumDate).toISOString()
        }
      />
    );
  } else {
    return <p className="user__text">{dayjs(date).format("DD.MM.YYYY")}</p>;
  }
};

const generateSelectionPicker = (
  editMode,
  editedSubPlan,
  onChange,
  options
) => {
  if (editMode) {
    return (
      <Select
        defaultValue={editedSubPlan}
        style={{ width: "100%" }}
        onChange={onChange}
        options={options}
      />
    );
  } else {
    return <p className="user__text">{editedSubPlan}</p>;
  }
};

export const User = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [currentGatewayIndex, setCurrentGatewayIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [attachedGateways, setAttachedGateways] = useState([]);
  const [attachedNotifications, setAttachedNotifications] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editedName, setEditedName] = useState();
  const [editedEmail, setEditedEmail] = useState();
  const [editedPhone, setEditedPhone] = useState();
  const [editedCompany, setEditedCompany] = useState();
  const [editedAddress, setEditedAddress] = useState();
  const [editedNationalID, setEditedNationalID] = useState();
  const [editedBirthDate, setEditedBirthDate] = useState();
  const [editedUsername, setEditedUsername] = useState();
  const [editeduserID, setEditeduserID] = useState();
  const [editedSubPlan, setEditedSubPlan] = useState();
  const [editedSubStartDate, setEditedSubStartDate] = useState();
  const [editedSubEndDate, setEditedSubEndDate] = useState();
  const [editedTotalSubBefore, setEditedTotalSubBefore] = useState();
  const [editedFirstSub, setEditedFirstSub] = useState();
  const [editedBadge, setEditedBadge] = useState();

  useEffect(() => {
    const user = data.userData.find((user) => user.id === +id);

    if (user) {
      setUserData(user);
      setEditedName(user.name);
      setEditedEmail(user.email);
      setEditedPhone(user.phone);
      setEditedCompany(user.company);
      setEditedAddress(user.address);
      setEditedNationalID(user.nationalID);
      setEditedBirthDate(user.birthDate);
      setEditedUsername(user.username);
      setEditeduserID(user.id);
      setEditedSubPlan(user.subPlan);
      setEditedSubStartDate(user.subStartDate);
      setEditedSubEndDate(user.subEndDate);
      setEditedTotalSubBefore(user.totalSubBefore);
      setEditedFirstSub(user.firstSub);
      setEditedBadge(user.badge);
    }

    const gatewaysToAttach = data.gatewayData.filter((gateway) =>
      user.ownGatewayIDs.includes(gateway.id)
    );

    const notificationsToAttach = data.notificationsData.filter(
      (notification) => user.ownNotificationIDs.includes(notification.id)
    );

    setAttachedNotifications(notificationsToAttach);
    setAttachedGateways(gatewaysToAttach);
  }, [id]);

  if (!userData) return null;

  const percentage = CalculatePercentage(
    dayjs(userData.subStartDate),
    dayjs(userData.subEndDate)
  );

  const subscriptionPercentage = [
    { name: "percentage", value: percentage },
    { name: "total - percentage", value: 100 - percentage },
  ];

  const handlePaginationButtonClick = (direction) => {
    let newIndex;

    if (direction === "next") {
      newIndex =
        currentGatewayIndex === attachedGateways.length - 1
          ? 0
          : currentGatewayIndex + 1;
    } else if (direction === "previous") {
      newIndex =
        currentGatewayIndex === 0
          ? attachedGateways.length - 1
          : currentGatewayIndex - 1;
    }

    setCurrentGatewayIndex(newIndex);
  };

  const handleFreezeButton = () => {
    console.log("Freeze button clicked!");
  };

  const handleEditButton = () => setEditMode(true);
  const handleCancelButton = () => {
    setEditedName(userData.name);
    setEditedEmail(userData.email);
    setEditedPhone(userData.phone);
    setEditedCompany(userData.company);
    setEditedAddress(userData.address);
    setEditedNationalID(userData.nationalID);
    setEditedBirthDate(userData.birthDate);
    setEditedUsername(userData.username);
    setEditeduserID(userData.id);
    setEditedSubPlan(userData.subPlan);
    setEditedSubStartDate(userData.subStartDate);
    setEditedSubEndDate(userData.subEndDate);
    setEditedTotalSubBefore(userData.totalSubBefore);
    setEditedFirstSub(userData.firstSub);
    setEditedBadge(userData.badge);

    setEditMode(false);
  };

  const handleSaveButton = () => {
    setUserData({
      ...userData,
      name: editedName,
      email: editedEmail,
      phone: editedPhone,
      company: editedCompany,
      address: editedAddress,
      nationalID: editedNationalID,
      birthDate: editedBirthDate,
      username: editedUsername,
      id: editeduserID,
      subPlan: editedSubPlan,
      subStartDate: editedSubStartDate,
      subEndDate: editedSubEndDate,
      totalSubBefore: editedTotalSubBefore,
      firstSub: editedFirstSub,
      badge: editedBadge,
    });

    setEditMode(false);
  };

  const handleInputChange = (updateState) => (e) => updateState(e.target.value);

  return (
    <div
      className="page-outer-container"
      style={{ display: "flex", flexDirection: "column", gap: 16 }}
    >
      <div className="page-buttons-area">
        {editMode && (
          <>
            <Button onClick={handleSaveButton} type="primary">
              Save Changes
            </Button>
            <Button onClick={handleCancelButton} type="primary" danger>
              Discard
            </Button>
            <Button
              onClick={handleFreezeButton}
              type="primary"
              style={{ background: "orange" }}
            >
              Freeze Account
            </Button>
          </>
        )}
        <Button onClick={handleEditButton} style={{ alignSelf: "flex-end" }}>
          Edit
        </Button>
        <Button
          onClick={() => {
            window.history.back();
          }}
          style={{ alignSelf: "flex-end" }}
        >{`<`}</Button>
      </div>
      <Row gutter={[16, 16]}>
        <Col xxl={12} xl={24} lg={24} md={24} sm={24}>
          <CustomCard title="User Details">
            <div className="user__card-container">
              <div className="user__box-row">
                {generateUserDetailBox(
                  "Name",
                  generateInput(
                    editMode,
                    editedName,
                    handleInputChange(setEditedName)
                  )
                )}
                {generateUserDetailBox(
                  "Email",
                  generateInput(
                    editMode,
                    editedEmail,
                    handleInputChange(setEditedEmail)
                  )
                )}
                {generateUserDetailBox(
                  "Phone",
                  generateInput(
                    editMode,
                    editedPhone,
                    handleInputChange(setEditedPhone)
                  )
                )}
              </div>
              <div className="user__box-row">
                {generateUserDetailBox(
                  "User ID",
                  generateInput(
                    editMode,
                    editeduserID,
                    handleInputChange(setEditeduserID)
                  )
                )}
                {generateUserDetailBox(
                  "Company",
                  generateInput(
                    editMode,
                    editedCompany,
                    handleInputChange(setEditedCompany)
                  )
                )}
                {generateUserDetailBox(
                  "Address",
                  generateInput(
                    editMode,
                    editedAddress,
                    handleInputChange(setEditedAddress)
                  )
                )}
              </div>
              <div className="user__box-row">
                {generateUserDetailBox(
                  "National ID",
                  generateInput(
                    editMode,
                    editedNationalID,
                    handleInputChange(setEditedNationalID)
                  )
                )}
                {generateUserDetailBox(
                  "Birth Date",
                  generateDatePicker(
                    editMode,
                    userData.birthDate,
                    (dateString) => setEditedBirthDate(dateString),
                    dayjs(userData.birthDate).format("DD.MM.YYYY")
                  )
                )}
                {generateUserDetailBox(
                  "Username",
                  generateInput(
                    editMode,
                    editedUsername,
                    handleInputChange(setEditedUsername)
                  )
                )}
              </div>
            </div>
          </CustomCard>
        </Col>
        <Col xxl={12} xl={24} lg={24} md={24} sm={24}>
          <CustomCard
            title="Subscription Details"
            addedBodyStyle={{ display: "flex" }}
          >
            <div>
              <PieChart width={240} height={240}>
                <text
                  x={`50%`}
                  y={`50%`}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  style={{
                    fill: "#154a45",
                    fontSize: 48,
                    fontWeight: "bold",
                  }}
                >
                  {Math.max(subscriptionPercentage[0].value.toFixed(0), 0)}
                  <tspan style={{ fontSize: 16 }}>%</tspan>
                </text>
                <Pie
                  data={subscriptionPercentage}
                  labelLine={false}
                  cx="50%"
                  cy="50%"
                  innerRadius={75}
                  outerRadius={100}
                  dataKey="value"
                >
                  {subscriptionPercentage.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        [`url(#gradient1)`, "#ccc"][
                          index % subscriptionPercentage.length
                        ]
                      }
                    />
                  ))}
                </Pie>
                <defs>
                  <linearGradient id="gradient1">
                    <stop offset="0%" stopColor="#225533 " />
                    <stop offset="30%" stopColor="#13c5a9" />
                    <stop offset="60%" stopColor="#154a4a" />
                    <stop offset="100%" stopColor="#13c5a9 " />
                  </linearGradient>
                </defs>
              </PieChart>
            </div>
            <div className="user__card-container">
              <div className="user__box-row">
                {generateUserDetailBox(
                  "Subscription Type",
                  generateSelectionPicker(
                    editMode,
                    userData.subPlan,
                    (value) => setEditedSubPlan(value),
                    subOptions
                  )
                )}
                {generateUserDetailBox(
                  "Badge",
                  generateSelectionPicker(
                    editMode,
                    userData.badge,
                    (value) => setEditedBadge(value),
                    badgeOptions
                  )
                )}
              </div>
              <div className="user__box-row">
                {generateUserDetailBox(
                  "Start Date",
                  generateDatePicker(
                    editMode,
                    userData.subStartDate,
                    (dateString) => setEditedSubStartDate(dateString),
                    dayjs(userData.subStartDate).format("DD.MM.YYYY")
                  )
                )}
                {generateUserDetailBox(
                  "End Date",
                  generateDatePicker(
                    editMode,
                    userData.subEndDate,
                    (dateString) => setEditedSubEndDate(dateString),
                    dayjs(userData.subEndDate).format("DD.MM.YYYY"),
                    editedSubStartDate
                  )
                )}
              </div>
              <div className="user__box-row">
                {generateUserDetailBox(
                  "Total Months Before",
                  generateInput(
                    editMode,
                    editedTotalSubBefore,
                    handleInputChange(setEditedTotalSubBefore)
                  )
                )}
                {generateUserDetailBox(
                  "Member Since",
                  generateInput(
                    editMode,
                    dayjs(userData.firstSub).format("DD.MM.YYYY"),
                    handleInputChange(setEditedFirstSub)
                  )
                )}
              </div>
            </div>
          </CustomCard>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xxl={12} xl={24} lg={24} md={24} sm={24}>
          <CustomCard title="User Events">
            <CustomTable
              tableName={"User Events"}
              pageSize={5}
              columns={columns}
              dataSource={attachedNotifications}
              setSelectedItems={setSelectedItems}
            />
          </CustomCard>
        </Col>
        <Col xxl={12} xl={24} lg={24} md={24} sm={24}>
          <CustomCard title="Gateways">
            <div className="user__gateways">
              <div className="user__box-row">
                <button
                  onClick={() => handlePaginationButtonClick("previous")}
                  className="user__pagination-button"
                >{`<`}</button>
                <div className="user__box">
                  <SmileTwoTone />
                  <div className="user__text-box">
                    <p className="user__header">Name</p>
                    <p className="user__text">
                      {attachedGateways[currentGatewayIndex]?.name}
                    </p>
                  </div>
                </div>
                <div className="user__box">
                  <SmileTwoTone />
                  <div className="user__text-box">
                    <p className="user__header">Lease Type</p>
                    <p className="user__text">
                      {attachedGateways[currentGatewayIndex]?.leaseType}
                    </p>
                  </div>
                </div>
                <div className="user__box">
                  <SmileTwoTone />
                  <div className="user__text-box">
                    <p className="user__header">Status</p>
                    <p className="user__text">
                      {attachedGateways[currentGatewayIndex]?.status}
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => handlePaginationButtonClick("previous")}
                  className="user__pagination-button"
                >{`>`}</button>
              </div>
              <div className="user__map-container">
                <div className="user__map-container">
                  {attachedGateways.length > 0 ? (
                    <GatewayMap
                      filteredData={attachedGateways}
                      mapCenter={[
                        attachedGateways[currentGatewayIndex]?.lng,
                        attachedGateways[currentGatewayIndex]?.lat,
                      ]}
                    />
                  ) : (
                    <div className="user__no-gateway">No gateway found</div>
                  )}
                </div>
              </div>
            </div>
          </CustomCard>
        </Col>
      </Row>
    </div>
  );
};
