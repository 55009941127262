import { message } from "antd";

export const PopUpMessage = ({ type, content }) => {
  const messageApi = message;

  const displayErrorMessage = () => {
    messageApi[type]({
      content,
      style: { marginTop: "2.5rem" },
    });
  };

  return <>{displayErrorMessage()}</>;
};
