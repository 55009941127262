import { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import markerRed from "../assets/markerRed.png";
import markerGreen from "../assets/markerGreen.png";
import markerOrange from "../assets/markerOrange.png";
import data from "../data.json";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWVydGF2Y2lvZ2x1IiwiYSI6ImNsbTBvN3RldTNiaTIzcXRocXc4NnQ5am8ifQ.2iQGKq4Yc0uLyL1DcQDChQ";

const generateSensorPopupContent = (dataItem) => {
  return `
    <div class="custom-popup">
      <h1>${dataItem.name}</h1>
      <h3>${dataItem.status}</h3>
      <h4>${dataItem.description}</h4>
      <table>
        <tr><th>Temperature:</th><td>${dataItem.temp} °C</td></tr>
        <tr><th>Humidity:</th><td>${dataItem.moisture} %</td></tr>
        <tr><th>EC:</th><td>${dataItem.ec}</td></tr>
        <tr><th>Battery:</th><td>${dataItem.battery}</td></tr>
      </table>
    </div>`;
};

const generateGatewayPopupContent = (dataItem) => {
  const attachedSensors = data.sensorData.filter((sensor) =>
    dataItem.connectedSensorIDs.includes(sensor.id)
  );
  return `
    <div class="custom-popup">
      <h1 class="gatewayMap__h1">${dataItem.name}</h1>
      <h3 class="gatewayMap__h3">${dataItem.status}</h3>
      <h4 class="gatewayMap__h4">${dataItem.description}</h4>
      <table class="gatewayMap__table">
        <tr><th class="gatewayMap__td">Connected Sensors:</th><td class="gatewayMap__td">${
          attachedSensors.length
        }</td></tr>
        ${attachedSensors
          .map(
            (item, index) =>
              `<tr><th class="gatewayMap__td">${
                index === 0 ? "Sensors:" : ""
              }</th><td class="gatewayMap__td">${item.name}</td></tr>`
          )
          .join("")}
      </table>
    </div>`;
};

export const GatewayMap = ({ filteredData, mapCenter = null }) => {
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/outdoors-v12",
      center: mapCenter ? [mapCenter[1], mapCenter[0]] : [29.05, 40.2],
      zoom: 12,
    });

    filteredData.forEach((dataItem) => {
      const markerImage =
        dataItem.status === "inactive"
          ? markerRed
          : dataItem.status === "warning"
          ? markerOrange
          : markerGreen;

      const marker = new mapboxgl.Marker({
        element: Object.assign(document.createElement("div"), {
          className: "marker-image",
          innerHTML: `<img src=${markerImage} alt="Marker" className="marker-image" />`,
        }),
      })
        .setLngLat([dataItem.lat, dataItem.lng])
        .addTo(map);

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      marker.getElement().addEventListener("mouseover", () => {
        map.getCanvas().style.cursor = "pointer";

        let popupContent = ``;
        if (filteredData[0].type === "sensor") {
          popupContent = generateSensorPopupContent(dataItem);
        } else if (filteredData[0].type === "gateway") {
          popupContent = generateGatewayPopupContent(dataItem);
        }

        const coordinates = [dataItem.lat, dataItem.lng];

        popup.setLngLat(coordinates).setHTML(popupContent).addTo(map);
      });

      marker.getElement().addEventListener("mouseout", () => {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });
    });

    const pointCoordinates = filteredData.map((dataItem) => [
      dataItem.lat,
      dataItem.lng,
    ]);

    const bounds = pointCoordinates.reduce(
      (bounds, coord) => bounds.extend(coord),
      new mapboxgl.LngLatBounds(pointCoordinates[0], pointCoordinates[0])
    );

    if (!mapCenter && filteredData.length > 0) {
      map.fitBounds(bounds, { padding: window.innerHeight / 8 });
    }

    if (!mapCenter && filteredData.length === 1) {
      map.setCenter(bounds.getCenter());
      map.setZoom(12);
    }

    map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    map.on("load", function () {
      map.addSource("Points", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: filteredData.map(function (dataItem) {
            return {
              type: "Feature",
              properties: {
                name: dataItem.name,
                id: dataItem.id,
                description: dataItem.description,
                temp: dataItem.temp,
                moisture: dataItem.moisture,
                ec: dataItem.ec,
              },
              geometry: {
                type: "Point",
                coordinates: [dataItem.lat, dataItem.lng],
              },
            };
          }),
        },
      });

      map.addLayer({
        id: "Points",
        type: "symbol",
        source: "Points",
        layout: {
          "text-field": "{name}",
          "text-variable-anchor": ["top"],
          "text-radial-offset": 0.75,
        },
        paint: { "text-color": "blue" },
      });
    });
  }, [filteredData, mapCenter]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
      id="map"
    />
  );
};
