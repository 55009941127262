import { useState } from "react";
import data from "../data.json";
import { Link } from "react-router-dom";
import { Dropdown, Menu, Progress, Button } from "antd";
import { UserOutlined, WarningOutlined, DownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { CustomTable } from "../components/CustomTable";
import { CalculatePercentage } from "../components/functions";
import { StatusIcons } from "../components/StatusIcons";

const gatewayListColumns = [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Name",
  },
  {
    key: "leaseType",
    dataIndex: "leaseType",
    title: "Lease Type",
    filters: [
      {
        text: "sold",
        value: "sold",
      },
      {
        text: "rented",
        value: "rented",
      },
    ],
    render: (text, record) => (
      <div
        style={{
          display: "flex",
          gap: 10,
          alignItems: "center",
        }}
      >
        {StatusIcons[record.leaseType]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.leaseType.includes(value),
    sorter: (a, b) => a.leaseType.localeCompare(b.leaseType),
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status",
    filters: [
      {
        text: "active",
        value: "active",
      },
      {
        text: "inactive",
        value: "inactive",
      },
      {
        text: "warning",
        value: "warning",
      },
    ],
    render: (text, record) => (
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        {StatusIcons[record.status]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.status.includes(value),
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
  {
    key: "progressBar",
    dataIndex: "progressBar",
    title: "Progress Bar",
    sorter: (a, b) => {
      const gatewayOwnerA = data.userData.find((user) => user.id === a.userID);
      const gatewayOwnerB = data.userData.find((user) => user.id === b.userID);

      return (
        CalculatePercentage(
          dayjs(gatewayOwnerA.subStartDate),
          dayjs(gatewayOwnerA.subEndDate)
        ) -
        CalculatePercentage(
          dayjs(gatewayOwnerB.subStartDate),
          dayjs(gatewayOwnerB.subEndDate)
        )
      );
    },
    render: (_, record) => {
      const gatewayOwner = data.userData.find(
        (user) => user.id === record.userID
      );

      return (
        <Progress
          percent={CalculatePercentage(
            dayjs(gatewayOwner.subStartDate),
            dayjs(gatewayOwner.subEndDate)
          )}
          status="active"
          strokeColor={{ from: "#108ee9", to: "#87d068" }}
        />
      );
    },
  },
  {
    key: "stateTBD",
    dataIndex: "stateTBD",
    title: "stateTBD",
    width: window.innerWidth / 17,
    render: () => (
      <Link to="/users">
        <WarningOutlined style={{ color: "red", cursor: "pointer" }} />
      </Link>
    ),
  },
  {
    key: "profil",
    dataIndex: "profil",
    title: "Profil",
    width: window.innerWidth / 17,
    render: (_, record) => (
      <Link to={`/user/${record.userID}`}>
        <UserOutlined style={{ color: "green", cursor: "pointer" }} />
      </Link>
    ),
  },
  {
    key: "ayarlar",
    dataIndex: "ayarlar",
    title: "Ayarlar",
    width: window.innerWidth / 17,
    render: (_, record) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={() => handleEdit(record)}>
              Edit
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleDelete(record)}>
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <p style={{ cursor: "pointer" }}>
          More <DownOutlined />
        </p>
      </Dropdown>
    ),
  },
];

const sensorColumns = [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Name",
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status",
    filters: [
      {
        text: "active",
        value: "active",
      },
      {
        text: "inactive",
        value: "inactive",
      },
      {
        text: "warning",
        value: "warning",
      },
    ],
    render: (text, record) => (
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        {StatusIcons[record.status]}
        {text}
      </div>
    ),
    onFilter: (value, record) => record.status.includes(value),
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
  {
    key: "ec",
    dataIndex: "ec",
    title: "ec",
    width: window.innerWidth / 17,
    render: (_, record) => (
      <div>
        {record.ec} <span>µS/cm</span>
      </div>
    ),
  },
  {
    key: "temp",
    dataIndex: "temp",
    title: "temp",
    width: window.innerWidth / 20,
    render: (_, record) => (
      <div>
        {record.temp} <span>°C</span>
      </div>
    ),
  },
  {
    key: "moisture",
    dataIndex: "moisture",
    title: "moisture",
    width: window.innerWidth / 20,
    render: (_, record) => (
      <div>
        <span>%</span> {record.moisture}
      </div>
    ),
  },
  {
    key: "stateTBD",
    dataIndex: "stateTBD",
    title: "stateTBD",
    width: window.innerWidth / 17,
    render: () => (
      <Link to="/users">
        <WarningOutlined style={{ color: "red", cursor: "pointer" }} />
      </Link>
    ),
  },
  {
    key: "ayarlar",
    dataIndex: "ayarlar",
    title: "Ayarlar",
    width: window.innerWidth / 17,
    render: (_, record) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={() => handleEdit(record)}>
              Edit
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleDelete(record)}>
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <p style={{ cursor: "pointer" }}>
          More <DownOutlined />
        </p>
      </Dropdown>
    ),
  },
];

const handleEdit = (record) => {};
const handleDelete = (record) => {};

export const GatewayList = () => {
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleExpand = (record) => {
    setExpandedRowKey((prevKey) => (prevKey === record.id ? null : record.id));
  };

  const expandedRowRender = (gatewayId) => {
    return (
      <CustomTable
        columns={sensorColumns}
        dataSource={data.sensorData.filter(
          (item) => item.parentGatewayThingId === gatewayId
        )}
        pagination={false}
        setSelectedItems={setSelectedItems}
      />
    );
  };

  return (
    <div className="page-outer-container">
      <CustomTable
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record.id),
          defaultExpandedRowKeys: ["0"],
          expandedRowKeys: [expandedRowKey],
          onExpand: (expanded, record) => {
            handleExpand(record);
          },
        }}
        tableName={"GatewayList"}
        setSelectedItems={setSelectedItems}
        columns={gatewayListColumns}
        dataSource={data.gatewayData.filter(
          (item) => item.inWhere === "client"
        )}
        additionalButtons={
          <Link to={`/adddevice`} style={{ display: "flex", gap: 15 }}>
            <Button
              style={{
                alignSelf: "flex-end",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Add Device
            </Button>
          </Link>
        }
      />
    </div>
  );
};
