import { useEffect, useState, useRef } from "react";
import { Input, Button, Space, Table, Tooltip } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { ShortenText } from "../components/functions";

export const CustomTable = ({
  additionalButtons,
  setSelectedItems,
  expandable,
  tableName,
  columns,
  dataSource,
  pageSize = 10,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [tableKey, setTableKey] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const clearAllFilers = () => {
    setTableKey((tableKey) => tableKey + 1);
    setSearchedColumn("");
    setSearchText("");
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const valueToSearch = dataIndex
        .split(".")
        .reduce((obj, key) => obj?.[key], record);
      return valueToSearch
        ?.toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069" }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const updatedColumns = columns.map((column) => {
    if (tableName === "Notifications" && column.dataIndex === "message") {
      return {
        ...column,
        render: (_, record) => (
          <Tooltip placement="bottomLeft" title={record.message}>
            {ShortenText(record.message, 35)}
          </Tooltip>
        ),
      };
    }

    if (
      tableName === "UserList" &&
      (column.dataIndex === "username" || column.dataIndex === "name")
    ) {
      return {
        ...column,
        render: (_, record) => (
          <Link to={`/user/${record.id}`} style={{ display: "flex", gap: 15 }}>
            <UserOutlined style={{ color: "green" }} />
            <p style={{ color: "black" }}>{record[column.dataIndex]}</p>
          </Link>
        ),
      };
    }

    if (
      (tableName === "UserList" &&
        ["id", "name", "username"].includes(column.dataIndex)) ||
      (tableName === "Notifications" &&
        ["id", "message"].includes(column.dataIndex)) ||
      (tableName === "GatewayList" && ["id", "name"].includes(column.dataIndex))
    ) {
      return {
        ...column,
        ...getColumnSearchProps(column.dataIndex),
      };
    }

    return column;
  });

  const rowSelection = {
    type: "checkbox",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
      // console.log("Selected Items:", selectedRows);
    },
    getCheckboxProps: (record) => ({
      key: record.id,
    }),
  };

  return (
    <>
      <div className="customTable__buttons-area">
        {additionalButtons}
        <Button className="table-clear-button" onClick={clearAllFilers}>
          Clear All Filters
        </Button>
      </div>
      <Table
        expandable={expandable}
        key={tableKey}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          pageSize: pageSize,
          hideOnSinglePage: true,
        }}
        tableLayout="fixed"
        columns={updatedColumns}
        dataSource={dataSource}
        rowSelection={rowSelection}
        bordered={true}
        style={{ cursor: "default" }}
        loading={isLoading ? true : false}
      />
    </>
  );
};
