import { Link } from "react-router-dom";
import { Avatar, Layout } from "antd";
import { UserOutlined } from "@ant-design/icons";

export const Header = () => {
  return (
    <Layout.Header
      style={{
        display: "flex",
        backgroundColor: "#154a45",
        width: "100%",
        color: "white",
        height: "2.5rem",
        alignItems: "center",
        justifyContent: "space-between",
        position: "fixed",
        zIndex: 999999999,
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => {
          console.log("logging out");
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("isLoggedIn");
          window.location.href = "/";
        }}
      >
        Log Out
      </button>
      <div />
      <div
        style={{
          display: "flex",
          gap: 5,
          alignItems: "center",
          width: 225,
        }}
      >
        <Link to="/">
          <Avatar
            style={{ backgroundColor: "#87d068", cursor: "pointer" }}
            icon={<UserOutlined />}
          />
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            fontSize: "0.75rem",
          }}
        >
          <p>username</p>
          <p>name.surname@mikroterra.com</p>
        </div>
      </div>
    </Layout.Header>
  );
};
