import "./Login.css";
import { useEffect } from "react";

export const Login = ({ handleLogin }) => {
  useEffect(() => {
    var loginForm = document.getElementById("login-form");
    if (loginForm) {
      loginForm.addEventListener("keypress", function (e) {
        var key = e.which || e.keyCode;
        if (key === 13) {
          submitForm(e);
        }
      });
    }

    return () => {
      if (loginForm) {
        loginForm.removeEventListener("keypress", function (e) {
          var key = e.which || e.keyCode;
          if (key === 13) {
            submitForm(e);
          }
        });
      }
    };
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    const loginEmail = document.getElementById("login-email");
    const loginPassword = document.getElementById("login-password");

    const submitObject = {
      loginEmail: loginEmail.value,
      loginPassword: loginPassword.value,
    };
    handleLogin(submitObject);
  };

  return (
    <div className="login-outer-container">
      <div className="login-container">
        <img
          src="https://mktrcdn.s3.amazonaws.com/appIcons/Mikroterra-OS-Logo.svg"
          alt=""
          style={{ width: "40vh" }}
        />
        <p className="login-cc-text">Command Center</p>
        <form className="login-form" onSubmit={submitForm} id="login-form">
          <input
            className="login-input"
            type="text"
            id="login-email"
            name="login-email"
            placeholder="E-postanız"
          />
          <input
            className="login-input"
            type="password"
            id="login-password"
            name="login-password"
            placeholder="Şifreniz"
          />
          <button className="login-button" type="submit" onClick={submitForm}>
            Giriş Yap
          </button>
        </form>
      </div>
    </div>
  );
};
