import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import { Sidebar } from "./components/Sidebar";
import { Header } from "./components/Header";
import { GatewayList } from "./pages/GatewayList";
import { FleetManagement } from "./pages/FleetManagement";
import { UserList } from "./pages/UserList";
import { Notifications } from "./pages/Notifications";
import { User } from "./pages/User";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { TwoFactorAuth } from "./components/TwoFactorAuth";
import { Warehouse } from "./pages/Warehouse";
import { AddDevice } from "./pages/AddDevice";
import { ResetPassword } from "./pages/ResetPassword";
import { PopUpMessage } from "./components/PopUpMessage";
import axios from "axios";
import { url } from "./components/functions";
import { RegisterUser } from "./pages/RegisterUser";
import { NewWarehouse } from "./pages/NewWarehouse";

const App = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [showTwoFactor, setShowTwoFactor] = useState(false);
  const [resetPwScreen, setResetPwScreen] = useState(false);
  const [showQR, setShowQR] = useState(false);

  useEffect(() => {
    localStorage.getItem("token") && setIsLoggedIn(true);

    if (localStorage.getItem("token")) {
      axios
        .get(url + "/auth/user", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          setCurrentUser(response.data);
          if (response.data.state === "newPasswordRequired") {
            setResetPwScreen(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleLogin = ({ loginEmail, loginPassword }) => {
    axios
      .post(url + "/auth/login", {
        user: loginEmail,
        password: loginPassword,
      })
      .then((response) => {
        console.log(response.data);
        setCurrentUser(response.data);
        if (response.data.state === "newPasswordRequired") {
          setResetPwScreen(true);
        } else {
          // if (response.data.state === "mfaSetupRequired") {
          // PopUpMessage({ type: "success", content: "Success!" });
          // setShowQR(true);
          // setShowTwoFactor(true);
          // } else {
          PopUpMessage({ type: "success", content: "Success!" });
          setShowQR(false);
          localStorage.setItem("token", response.data.accessToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          setIsLoggedIn(true);
          // setShowTwoFactor(true);
          // }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetPassword = ({
    loginUsername,
    currentPassword,
    newPassword,
    newPassword2,
  }) => {
    if (
      loginUsername !== "" &&
      currentPassword !== "" &&
      newPassword !== "" &&
      newPassword2 !== "" &&
      newPassword === newPassword2
    ) {
      axios
        .post(url + "/auth/initial-password-change", {
          user: loginUsername,
          currentPassword: currentPassword,
          newPassword: newPassword,
        })
        .then((response) => {
          PopUpMessage({ type: "success", content: "Success!" });
          setShowQR(true);
          // setShowTwoFactor(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      PopUpMessage({ type: "error", content: "Error!" });
    }
  };

  const submitTwoFactorCode = (code) => {
    const endpoint = showQR ? "/auth/verify-token" : "/auth/check-mfa";

    axios
      .post(url + endpoint, {
        user: currentUser.userCognito.username,
        token: code,
        session: currentUser.userCognito.Session,
      })
      .then((response) => {
        PopUpMessage({ type: "success", content: "Success!" });
        localStorage.setItem("token", response.data.accessToken.jwtToken);
        localStorage.setItem("refreshToken", response.data.refreshToken.token);
        // setShowTwoFactor(false);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        console.log(error);
        PopUpMessage({ type: "error", content: "Error!" });
      });
  };

  // return !isLoggedIn ? (
  //   <div>
  //     {!showTwoFactor ? (
  //       !resetPwScreen ? (
  //         <Login handleLogin={handleLogin} />
  //       ) : (
  //         <ResetPassword
  //           username={currentUser.userAttributes.username}
  //           resetPassword={resetPassword}
  //         />
  //       )
  //     ) : (
  //       <TwoFactorAuth
  //         showQR={showQR}
  //         username={currentUser.userCognito.username} // user'dı, üsttekini değiştirdiğim için bunu da değiştirdim.
  //         secretCode={currentUser.secretCode}
  //         submitTwoFactorCode={submitTwoFactorCode}
  //       />
  //     )}
  //   </div>
  // ) : (
  //   <Router>
  //     <Sidebar />
  //     <Header />
  //     <Layout.Content
  //       style={{
  //         textAlign: "center",
  //         marginLeft: "200px",
  //         minHeight: "calc(100vh - 2.5rem)",
  //         marginTop: "2.5rem",
  //       }}
  //     >
  //       <Routes>
  //         <Route exact path="/" element={<Home />} />
  //         <Route exact path="/fleet" element={<FleetManagement />} />
  //         <Route exact path="/gatewaylist" element={<GatewayList />} />
  //         <Route exact path="/notifications" element={<Notifications />} />
  //         <Route exact path="/userlist" element={<UserList />} />
  //         <Route exact path="/user/:id" element={<User />} />
  //         <Route exact path="/warehouse" element={<Warehouse />} />
  //         <Route exact path="/adddevice" element={<AddDevice />} />
  //         <Route exact path="/registeruser" element={<RegisterUser />} />;
  //       </Routes>
  //     </Layout.Content>
  //   </Router>
  // );

  return !isLoggedIn ? (
    !resetPwScreen ? (
      <div>
        <Login handleLogin={handleLogin} />
      </div>
    ) : (
      <div>
        <ResetPassword
          username={currentUser.userAttributes.username}
          resetPassword={resetPassword}
        />
      </div>
    )
  ) : (
    <Router>
      <Sidebar />
      <Header />
      <Layout.Content
        style={{
          textAlign: "center",
          marginLeft: "200px",
          minHeight: "calc(100vh - 2.5rem)",
          marginTop: "2.5rem",
        }}
      >
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/fleet" element={<FleetManagement />} />
          <Route exact path="/gatewaylist" element={<GatewayList />} />
          <Route exact path="/notifications" element={<Notifications />} />
          <Route exact path="/userlist" element={<UserList />} />
          <Route exact path="/user/:id" element={<User />} />
          <Route exact path="/warehouse" element={<Warehouse />} />
          <Route exact path="/adddevice" element={<AddDevice />} />
          <Route exact path="/registeruser" element={<RegisterUser />} />
          <Route exact path="/newwarehouse" element={<NewWarehouse />} />
        </Routes>
      </Layout.Content>
    </Router>
  );
};

export default App;
