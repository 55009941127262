import { Button, Col, Form, Input, Row } from "antd";
import { CustomCard } from "../components/CustomCard";
import { PopUpMessage } from "../components/PopUpMessage";

export const RegisterUser = () => {
  const [form] = Form.useForm();

  const handleFormSubmit = (values, form) => {
    console.log("Form submitted!", values);
    form.resetFields();
    PopUpMessage({ type: "success", content: "User registered!" });
  };

  return (
    <div className="page-outer-container">
      <Row gutter={[16, 16]}>
        <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
          <CustomCard title="Register User">
            <Form
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              layout="horizontal"
              style={{ maxWidth: 600, textAlign: "left" }}
              onFinish={(values) => handleFormSubmit(values, form)}
              onFinishFailed={() =>
                PopUpMessage({
                  type: "error",
                  content: "Cannot register user!",
                })
              }
            >
              <Form.Item
                hasFeedback
                label="name"
                name="name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                hasFeedback
                label="email"
                name="email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                hasFeedback
                label="password"
                name="password"
                rules={[{ min: 8, required: true }]}
              >
                <Input minLength={8} maxLength={16} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#154a45" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </CustomCard>
        </Col>
      </Row>
    </div>
  );
};
